// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Font-Awesome
@import "~fontawesome-free/scss/fontawesome";
@import "~fontawesome-free/scss/regular";
@import "~fontawesome-free/scss/solid";
@import "~fontawesome-free/scss/brands";